import { useContext } from 'react';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';
import { RelationshipNotesModalContext } from '../RelationshipNotesModalContext';
import { ConfirmationModal } from 'modals/ConfirmationModal';
import { IButtonAction } from 'components/ActionButton';

export function RelationshipDiscardNoteChangesModal() {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const discard_unsaved_changes_modal =
    baseLanguage.relationships?.notes?.notes_modal?.notes_modal_create_edit_note
      ?.discard_unsaved_changes_modal;
  const {
    isDiscardNoteChangesModalOpen,
    setIsDirtyNote,
    setIsDiscardNoteChangesModalOpen,
    onConfirmDiscardNoteChanges,
    setOnConfirmDiscardNoteChanges,
  } = useContext(RelationshipNotesModalContext);

  const resetDiscardNoteChangesModal = () => {
    setOnConfirmDiscardNoteChanges(undefined);
    setIsDiscardNoteChangesModalOpen(false);
  };

  const actions: IButtonAction[] = [
    {
      label: baseLanguage.globals?.common?.discard_changes_button_label,
      variant: 'contained',
      color: 'error',
      action: () => {
        onConfirmDiscardNoteChanges?.();
        setIsDirtyNote(false);
        setIsDiscardNoteChangesModalOpen(false);
      },
    },
    {
      label: discard_unsaved_changes_modal?.continue_editing_button_label,
      variant: 'contained',
      color: 'info',
      action: resetDiscardNoteChangesModal,
    },
  ];

  return (
    <ConfirmationModal
      open={isDiscardNoteChangesModalOpen}
      title={discard_unsaved_changes_modal?.title}
      description={discard_unsaved_changes_modal?.description}
      actions={actions}
      handleClose={resetDiscardNoteChangesModal}
    />
  );
}
